.upload-circle{
  line-height: 1 !important;
  cursor: pointer;
}

.ant-carousel .guidelines-carousel .slick-dots li button{
  background: #ffc727;
  border-radius: 12px;
  height: 4px;
  margin-top: 12px;
}

.guidelines-wrapper{
  padding: 0 0 60px 0;
}

.shadow-white-button{
  border : none;
  box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(64 68 82 / 8%) 0px 2px 5px 0px;
}

.border-circle{
  border-image: linear-gradient(to right, black 50%, transparent 50%) 0 0 10px;
}

.footer-save-ticket-user,
.ticket_user-form-item{
  width: 100%;
  margin: auto;
}


/* version 2 */
.ticket-detail-wrapper{
  /* background: linear-gradient(180deg, #2d3a71 0%, #15284b 100%); */
  /* border-radius: 24px; */
  padding: 4px 0;
  min-height: calc(100vh - 64px);
}

.ticket-detail-form{
  border-radius: 24px;
  background: #fff;
}

.footer-save-ticket-user.disabled{
  background : var(--secondary-button-color);
  color : var(--secondary-text-color);
}


@media (max-width : 768px){
  .footer-save-ticket-user{
    position: fixed;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background : var(--main-color);
  }
}

@media (min-width: 960px){
  .save-ticket-user-content{
    min-height:calc(100vh - var(--header-height) - 2rem - 180px);
  }
  
  .footer-save-ticket-user,
  .ticket_user-form-item{
    width: 400px !important;

  }

  .personal-detail-form{
    display: flex;
    justify-content: center;
    width: 100%;
  }
}