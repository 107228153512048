@font-face {
    font-family: retroGaming;
    src: url(../helpers/fonts/retro_gaming.ttf);
}

@font-face {
    font-family: NeuePlak;
    src: url(../helpers//fonts/neue-plak-cufonfonts/Neue\ Plak\ Regular.ttf);
}

:root{
    --header-height : 64px;
    --scrollbar-width : 5px;
    /* --main-color : #FFC727; */
    --main-color : #1E4385;
    --secondary-text-color : #6f7287;
    --secondary-button-color : #f8f7fa;
}

body, .scroll-item, .ant-modal-wrap{
    overflow-y : scroll;
}

body::-webkit-scrollbar,
.scroll-item::-webkit-scrollbar,
.ant-modal-wrap::-webkit-scrollbar{
    width: var(--scrollbar-width);
}

body::-webkit-scrollbar,
.scroll-item::-webkit-scrollbar,
.ant-modal-wrap::-webkit-scrollbar
{
    width: var(--scrollbar-width);
    background: rgb(239, 243, 246);
    height: 5px;
}
body::-webkit-scrollbar-thumb,
.scroll-item::-webkit-scrollbar-thumb,
.ant-modal-wrap::-webkit-scrollbar-thumb
{
    background-color: rgb(199, 208, 221);
    border-radius: 20px;
}

.content{
    overflow-x: hidden;
    overflow-y: hidden;
    min-height: calc(100vh - 64px);
}

.main-content{
    /* min-height: 92vh;
    background: #f7fafc; */
    background:#fff;
    min-height: calc(100vh - var(--header-height));
}

.main-menu.ant-menu{
    background: transparent;
    padding: 0 14px;
}

.main-menu.ant-menu .ant-menu-item{
    border-radius: 8px;
    display: flex;
    align-items : center;
}

.main-menu.ant-menu .ant-menu-item.ant-menu-item-selected{
    background-color: #575759;
}

.sub-menu-select{
    cursor: pointer;
}

.sub-menu-select .right-arrow{
    color: #8c8c8c;
    /* transform: translateX(-100%); */
    transition: all 0.5s;
}

.sub-menu-select:hover .right-arrow{
    color: #000;
    transform: translateX(30%);
}

.input_wrapper > input,
.input_wrapper > .ant-input-affix-wrapper{
    border-radius: 8px;
    /* border: none; */
    background: #fff;
}

.button-shadow.secondary{
    box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(64 68 82 / 8%) 0px 2px 5px 0px;
}

.custom-modal .ant-modal-header{
    border-radius: 6px 6px 0 0;   
}

.custom-modal .ant-modal-content{
    border-radius: 6px;
}

/* table */
.hub-table.bordered .ant-table{
    border : 1px solid #ebeef5;
    border-radius: 8px;
}

.hub-table .ant-table{
    /* border : 1px solid #f0f0f0; */
    border-radius: 8px;
}

.hub-table-list .ant-card-body{
    padding: 24px 0;
}

.hub-table-list .ant-table-pagination.ant-pagination{
    margin: 16px;
}

.hub-table .ant-table-thead > tr > th{
    background: #f7fafc;
    border-bottom : 1px solid #f5f5f5;
    color : #434343;
}

/* .hub-table .ant-table-thead > tr > th{
    background: #fff;
    border-bottom : 1px solid #f5f5f5;
    color : #434343;
} */

.hub-table .ant-table-tbody > tr > td{
    /* border-bottom: none; */
    border-bottom : 1px solid #f5f5f5;
    color: #595959;
}

.hub-table .ant-table-container table > thead > tr:first-child th:first-child{
    border-top-left-radius : 8px;
    padding-left: 20px;
    /* padding: 0 40px; */
}

.hub-table .ant-table-tbody > tr > td:first-child{
    padding-left: 20px;
    /* padding: 0 20px; */
}

.hub-table .ant-table-container table > thead > tr:first-child th:last-child{
    border-top-right-radius : 8px;
}
.ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before{
    display: none;
}




.hub-table .action-td{
    text-align: right;
}

/* button */
.mini-button{
    font-weight: 500;
    /* box-shadow: rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 12%) 0px 1px 1px 0px, rgb(64 68 82 / 16%) 0px 0px 0px 1px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(0 0 0 / 0%) 0px 0px 0px 0px, rgb(64 68 82 / 8%) 0px 2px 5px 0px; */
}

/* input item */
.border-input,
.border-input .ant-select-selector{
    border-radius: 6px !important;
}

.pixel-label{
    font-family: retroGaming;
}

.ticket-label{
    font-family: Poppins,sans-serif;
}

.quill-reader .ql-editor{
    padding : 12px 0;
}

.card-shadow{
    box-shadow: 0 5px 10px rgb(154 160 185/5%), 0 15px 40px rgb(166 173 201/20%);
}

/* antd button */
.ant-btn.ant-btn-primary{
    box-shadow: none;
}

@media (max-width : 768px) {
    .mobile-padding-24{
        padding : 24px;
    }

    .mobile-padding-12{
        padding : 12px;
    }
    
    .mobile-padding-left-right-24{
        padding : 0 24px;
    }
}
